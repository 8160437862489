<template>
  <v-container
    class="permissions-container"
    fluid
  >
    <v-card>
      <v-card-text>
        <v-row
          justify="space-between"
        >
          <v-col cols="12">
            <v-tabs>
              <v-tab
                v-for="(role, roleKey) in items.roles"
                :key="roleKey"
              >
                <v-icon left>
                  mdi-account
                </v-icon>
                {{ role }}
              </v-tab>

              <v-tab-item
                v-for="(role, roleKey) in items.roles"
                :key="roleKey"
              >
                <v-row class="mt-5">
                  <v-col
                    cols="12"
                    md="2"
                    sm="3"
                  >
                    <v-treeview
                      class=""
                      :items="items.permissions"
                      :active.sync="active"
                      :open.sync="open"
                      activatable
                      color="warning"
                      open-on-click
                      transition
                    >
                      <template v-slot:prepend="{ item }">
                        <v-icon>
                          mdi-{{ item.icon || 'file-document-outline' }}
                        </v-icon>
                      </template>
                      <template v-slot:label="{ item }">
                        <label>{{ $t(`users.permissions.tree.${item.name}`) }}</label>
                      </template>
                    </v-treeview>
                  </v-col>

                  <v-divider vertical />

                  <v-col class="pa-5">
                    <v-scroll-y-transition mode="out-in">
                      <div
                        v-if="!selected"
                        class="text-h6 grey--text text--lighten-1 font-weight-light"
                      >
                        <v-alert
                          icon="mdi-school"
                          prominent
                          text
                          type="info"
                        >
                          {{ $t(`users.permissions.selectSiteSection`) }}
                        </v-alert>
                      </div>
                      <div
                        v-else
                        :key="selected.id"
                      >
                        <v-row>
                          <v-col
                            cols="12"
                            class="mb-5"
                          >
                            <div class="text-uppercase font-weight-bold">
                              {{ role }} /
                              {{ selected[0].id.replace('.', ' ') }} /
                              {{ $t(`users.permissions.tree.${selected[0].name}`) }}
                            </div>
                          </v-col>
                          <v-col
                            v-for="(act, actKey) in selected[0].actions"
                            :key="actKey"
                            cols="12"
                            md="2"
                            class="pb-0 pt-0"
                          >
                            <v-switch
                              :value="true"
                              :input-value="getValue(role, selected[0].id, act) === true"
                              hide-details
                              :false-value="false"
                              :true-value="true"
                              @change="save(role, selected[0].id, act, $event)"
                            >
                              <template v-slot:label>
                                <span
                                  :class="`text-uppercase`"
                                >{{ act }}</span>
                              </template>
                            </v-switch>
                            <v-divider class="mt-2 mb-2" />
                          </v-col>
                        </v-row>
                      </div>
                    </v-scroll-y-transition>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

export default {
  name: 'Permissions',
  metaInfo () {
    return {
      title: this.$store.getters['app/appTitle'],
      titleTemplate: `${this.$t('users.permissions.meta.title')} — %s`
    }
  },
  data: () => ({
    title: '',
    valid: true,
    active: [],
    open: [],
    values: [],
    userId: undefined
  }),
  computed: {
    items () {
      return this.$store.getters['usersPermissions/permissionsList']
    },
    selected () {
      if (!this.active.length) {
        return undefined
      }
      const id = this.active[0]
      return this.items.permissions.map((item) => this.findNode(id, item)).filter(Boolean)
    }
  },
  watch: {
    active(valNew, valOld) {
      if (valNew !== null && valNew !== valOld) {
        window.scroll({ top: 0, behavior: 'smooth' })
      }
    }
  },
  async mounted () {
    this.title = this.$t('users.permissions.title')
    await this.load()
  },
  methods: {
    async load () {
      try {
        this.loading = true
        await this.$store.dispatch('usersPermissions/getPermissionsList')
      } catch (e) {
        await this.$toast.error(e.message)
      } finally {
        this.loading = false
      }
    },
    findNode (id, currentNode) {
      let i,
        currentChild,
        result
      if (id === currentNode.id) {
        return currentNode
      } else {
        if (typeof currentNode.children === 'undefined') {
          return false
        }
        for (i = 0; i < currentNode.children.length; i += 1) {
          currentChild = currentNode.children[i]
          result = this.findNode(id, currentChild)
          if (result !== false) {
            return result
          }
        }
        return false
      }
    },
    async save (role, resource, action, value) {
      await this.$store.dispatch('usersPermissions/savePermission', { role, resource, action, value })
    },
    getValue (role, resource, action) {
      try {
        return Boolean(this.items.values[role][resource][action])
      } catch (e) {
        return false
      }
    }
  }
}
</script>

<style lang="scss">
#app {
  .permissions-container {
    .v-input--selection-controls {
      margin-top: 0;
    }

    .v-input--selection-controls__input {
      position: absolute;
      right: 0;
    }
  }
}
</style>
